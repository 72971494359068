<template>
    <div class="">
        <form >

            <!-- paste from hq below here -->
            <h1>Insurance Era</h1>
            <div class="flex colx4 wrap">
                <div><Input
                    type="text"
                    placeholder="Insurance Payer Id"
                    v-model="record_data.insurance_payer_id"
                    label="Insurance Payer Id"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Payer Name"
                    v-model="record_data.payer_name"
                    label="Payer Name"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Payer Zip"
                    v-model="record_data.payer_zip"
                    label="Payer Zip"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Payer Acct"
                    v-model="record_data.payer_acct"
                    label="Payer Acct"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Check Num"
                    v-model="record_data.check_num"
                    label="Check Num"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Era Id"
                    v-model="record_data.era_id"
                    label="Era Id"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="date"
                    placeholder="Paid Date"
                    v-model="record_data.paid_date"
                    label="Paid Date"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Paid Amount"
                    v-model="record_data.paid_amount"
                    label="Paid Amount"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Provider Acct"
                    v-model="record_data.provider_acct"
                    label="Provider Acct"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Provider Name"
                    v-model="record_data.provider_name"
                    label="Provider Name"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Provirder Taxid"
                    v-model="record_data.provirder_taxid"
                    label="Provirder Taxid"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Provider Zip"
                    v-model="record_data.provider_zip"
                    label="Provider Zip"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Insurance Claim Ids"
                    v-model="record_data.insurance_claim_ids"
                    label="Insurance Claim Ids"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Response Json"
                    v-model="record_data.api_response_json"
                    label="Api Response Json"
                    :disabled="isDisabled"
                /></div>
                <!--form_extra-->
            </div>

            <div class="flex colx4 wrap">
                <!-- new entries start -->
                <div v-if="record_data.api_response_object.PatID"><Input
                    type="text"
                    placeholder="Api Patient ID"
                    v-model="record_data.api_response_object.PatID"
                    label="Api Patient Id"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.DRGCode"><Input
                    type="text"
                    placeholder="Api DRG Code"
                    v-model="record_data.api_response_object.DRGCode"
                    label="Api Patient Id"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.GroupNo"><Input
                    type="text"
                    placeholder="Api Group ID"
                    v-model="record_data.api_response_object.GroupNo"
                    label="Api Group ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.TransID"><Input
                    type="text"
                    placeholder="Api Trans ID"
                    v-model="record_data.api_response_object.TransID"
                    label="Api Trans Id"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.Balanced"><Input
                    type="text"
                    placeholder="Api Balanced"
                    v-model="record_data.api_response_object.Balanced"
                    label="Api Balanced"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.EditByID"><Input
                    type="text"
                    placeholder="Api Edit By ID"
                    v-model="record_data.api_response_object.EditByID"
                    label="Api Edit By ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.EditDate"><Input
                    type="date"
                    placeholder="Api Edit Date"
                    v-model="record_data.api_response_object.EditDate"
                    label="Api Edit Date"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.ParentID"><Input
                    type="text"
                    placeholder="Api Parent ID"
                    v-model="record_data.api_response_object.ParentID"
                    label="Api Parent ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.DRGWeight"><Input
                    type="text"
                    placeholder="Api DRG Weight"
                    v-model="record_data.api_response_object.DRGWeight"
                    label="Api DRG Weight"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.MediaCode"><Input
                    type="text"
                    placeholder="Api Media Code"
                    v-model="record_data.api_response_object.MediaCode"
                    label="Api Media Code"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.PatientID"><Input
                    type="text"
                    placeholder="Api Patient ID"
                    v-model="record_data.api_response_object.PatientID.MemberID"
                    label="Api Patient ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.TransType"><Input
                    type="text"
                    placeholder="Api Trans Type"
                    v-model="record_data.api_response_object.TransType"
                    label="Api Trans Type"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.CreateDate"><Input
                    type="date"
                    placeholder="Api CreateDate"
                    v-model="record_data.api_response_object.CreateDate"
                    label="Api Create Date"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.CreateMode"><Input
                    type="text"
                    placeholder="Api Create Mode"
                    v-model="record_data.api_response_object.CreateMode"
                    label="Api Create Mode"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.PayerCtlNo"><Input
                    type="text"
                    placeholder="Api Payer Ctl No"
                    v-model="record_data.api_response_object.PayerCtlNo"
                    label="Api Payer Ctl No"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.StatusCode"><Input
                    type="text"
                    placeholder="Api Status Code"
                    v-model="record_data.api_response_object.StatusCode"
                    label="Api Status Code"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.ClaimCharge"><Input
                    type="text"
                    placeholder="Api Claim Charge"
                    v-model="record_data.api_response_object.ClaimCharge"
                    label="Api Claim Charge"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.CoverageAmt"><Input
                    type="text"
                    placeholder="Api Coverage Amt"
                    v-model="record_data.api_response_object.CoverageAmt"
                    label="Api Coverage Amt"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.PatientLast"><Input
                    type="text"
                    placeholder="Api Patient Last"
                    v-model="record_data.api_response_object.PatientLast"
                    label="Api Patient Last"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.PatientResp"><Input
                    type="text"
                    placeholder="Api Patient Resp"
                    v-model="record_data.api_response_object.PatientResp"
                    label="Api Patient Resp"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.ProvMatchID"><Input
                    type="text"
                    placeholder="Api Prov Match ID"
                    v-model="record_data.api_response_object.ProvMatchID"
                    label="Api Prov Match ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.TransStatus"><Input
                    type="text"
                    placeholder="Api Trans Status"
                    v-model="record_data.api_response_object.TransStatus"
                    label="Api Trans Status"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.AssignedToID"><Input
                    type="text"
                    placeholder="Api Assigned To ID"
                    v-model="record_data.api_response_object.AssignedToID"
                    label="Api Assigned To ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.ClaimPayment"><Input
                    type="text"
                    placeholder="Api Claim Payment"
                    v-model="record_data.api_response_object.ClaimPayment"
                    label="Api Claim Payment"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.CrcPatientID"><Input
                    type="text"
                    placeholder="Api Crc Patient ID"
                    v-model="record_data.api_response_object.CrcPatientID.ChangedID"
                    label="Api Crc Patient ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.FacilityType"><Input
                    type="text"
                    placeholder="Api Facility Type"
                    v-model="record_data.api_response_object.FacilityType"
                    label="Api Facility Type"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.PatientCtlNo"><Input
                    type="text"
                    placeholder="Api Patient Ctl No"
                    v-model="record_data.api_response_object.PatientCtlNo"
                    label="Api Patient Ctl No"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.PatientFirst"><Input
                    type="text"
                    placeholder="Api Patient First"
                    v-model="record_data.api_response_object.PatientFirst"
                    label="Api Patient First"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.PayerMatchID"><Input
                    type="text"
                    placeholder="Api Payer Match ID"
                    v-model="record_data.api_response_object.PayerMatchID"
                    label="Api Payer Match ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.ReceivedDate"><Input
                    type="date"
                    placeholder="Api Received Date"
                    v-model="record_data.api_response_object.ReceivedDate"
                    label="Api Received Date"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.RemitClaimID"><Input
                    type="text"
                    placeholder="Api Remit Claim ID"
                    v-model="record_data.api_response_object.RemitClaimID"
                    label="Api Remit Claim ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.RemitGroupID"><Input
                    type="text"
                    placeholder="Api Remit Group ID"
                    v-model="record_data.api_response_object.RemitGroupID"
                    label="Api Remit Group ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.SourceFileID"><Input
                    type="text"
                    placeholder="Api Source File ID"
                    v-model="record_data.api_response_object.SourceFileID"
                    label="Api Source File ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.StatementEnd"><Input
                    type="date"
                    placeholder="Api Statement End"
                    v-model="record_data.api_response_object.StatementEnd"
                    label="Api Statement End"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.TransmitDate"><Input
                    type="date"
                    placeholder="Api Transmit Date"
                    v-model="record_data.api_response_object.TransmitDate"
                    label="Api Transmit Date"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.ClaimFreqCode"><Input
                    type="text"
                    placeholder="Api Claim Freq Code"
                    v-model="record_data.api_response_object.ClaimFreqCode"
                    label="Api Claim Freq Code"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.ImportBatchID"><Input
                    type="text"
                    placeholder="Api Import Batch ID"
                    v-model="record_data.api_response_object.ImportBatchID"
                    label="Api Import Batch ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.PatientMiddle"><Input
                    type="text"
                    placeholder="Api Patient Middle"
                    v-model="record_data.api_response_object.PatientMiddle"
                    label="Api Patient Middle"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.ProvPartnerID"><Input
                    type="text"
                    placeholder="Api Prov Partner ID"
                    v-model="record_data.api_response_object.ProvPartnerID"
                    label="Api Prov Partner ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.CrcPatientLast"><Input
                    type="text"
                    placeholder="Api Crc Patient Last"
                    v-model="record_data.api_response_object.CrcPatientLast"
                    label="Api Crc Patient Last"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.PayContactName"><Input
                    type="text"
                    placeholder="Api Pay Contact Name"
                    v-model="record_data.api_response_object.PayContactName"
                    label="Api Pay Contact Name"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.PayerPartnerID"><Input
                    type="text"
                    placeholder="Api Payer Partner ID"
                    v-model="record_data.api_response_object.PayerPartnerID"
                    label="Api Payer Partner ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.RemitPaymentID"><Input
                    type="text"
                    placeholder="Api Remit Payment ID"
                    v-model="record_data.api_response_object.RemitPaymentID"
                    label="Api Remit Payment ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.StatementStart"><Input
                    type="date"
                    placeholder="Api Statement Start"
                    v-model="record_data.api_response_object.StatementStart"
                    label="Api Statement Start"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.ClaimFilingCode"><Input
                    type="text"
                    placeholder="Api Claim Filing Code"
                    v-model="record_data.api_response_object.ClaimFilingCode"
                    label="Api Claim Filing Code"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.ClassContractNo"><Input
                    type="text"
                    placeholder="Api Class Contract No"
                    v-model="record_data.api_response_object.ClassContractNo"
                    label="Api Class Contract No"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.CrcPatientFirst"><Input
                    type="text"
                    placeholder="Api Crc Patient First"
                    v-model="record_data.api_response_object.CrcPatientFirst"
                    label="Api Crc Patient First"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.PayContactPhone"><Input
                    type="text"
                    placeholder="Api Pay Contact Phone"
                    v-model="record_data.api_response_object.PayContactPhone"
                    label="Api Pay Contact Phone"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.RenderingProvID"><Input
                    type="text"
                    placeholder="Api Rendering Prov ID"
                    v-model="record_data.api_response_object.RenderingProvID.NPI"
                    label="Api Rendering Prov ID"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.DischargeFraction"><Input
                    type="text"
                    placeholder="Api Discharge Fraction"
                    v-model="record_data.api_response_object.DischargeFraction"
                    label="Api Discharge Fraction"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.RenderingProvLast"><Input
                    type="text"
                    placeholder="Api Rendering Prov Last"
                    v-model="record_data.api_response_object.RenderingProvLast"
                    label="Api Rendering Prov Last"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.RenderingProvFirst"><Input
                    type="text"
                    placeholder="Api Rendering Prov First"
                    v-model="record_data.api_response_object.RenderingProvFirst"
                    label="Api Rendering Prov First"
                    :disabled="isDisabled"
                /></div>
                <div v-if="record_data.api_response_object.UnderpaymentAmount"><Input
                    type="text"
                    placeholder="Api Underpayment Amount"
                    v-model="record_data.api_response_object.UnderpaymentAmount"
                    label="Api Underpayment Amount"
                    :disabled="isDisabled"
                /></div>

            </div>
            <div class="flex colx4 wrap" v-for="service in record_data.api_response_object.Services" :key="service.HCPC">
                <div><Input
                    type="text"
                    placeholder="Api Service HCPC"
                    v-model="service.HCPC"
                    label="Api Service HCPC"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service Units"
                    v-model="service.Units"
                    label="Api UnitsHCPC"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service Charge"
                    v-model="service.Charge"
                    label="Api Service ChargeCPC"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service LineNo"
                    v-model="service.LineNo"
                    label="Api Service LineNoCPC"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service Allowed"
                    v-model="service.Allowed"
                    label="Api Service AllowedPC"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service Payment"
                    v-model="service.Payment"
                    label="Api Service PaymentPC"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service Balanced"
                    v-model="service.Balanced"
                    label="Api Service BalancedC"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service Modifier1"
                    v-model="service.Modifier1"
                    label="Api Service Modifier1"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service Modifier2"
                    v-model="service.Modifier2"
                    label="Api Service Modifier2"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service Modifier3"
                    v-model="service.Modifier3"
                    label="Api Service Modifier3"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service Modifier4"
                    v-model="service.Modifier4"
                    label="Api Service Modifier4"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service ProvCtl No"
                    v-model="service.ProvCtlNo"
                    label="Api Service ProvCtlNo"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service Units Paid"
                    v-model="service.UnitsPaid"
                    label="Api Service Units Paid"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service Revenue Code"
                    v-model="service.RevenueCode"
                    label="Api Service RevenueCode"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service Start"
                    v-model="service.ServiceStart"
                    label="Api Service ServiceStart"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service Underpayment"
                    v-model="service.Underpayment"
                    label="Api Service Underpayment"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service Underpayment Amount"
                    v-model="service.UnderpaymentAmount"
                    label="Api Service Underpayment Amount"
                    :disabled="isDisabled"
                /></div>
                <div><Input
                    type="text"
                    placeholder="Api Service Reimbursement Amount"
                    v-model="service.ReimbursementAmount"
                    label="Api Service Reimbursement Amount"
                    :disabled="isDisabled"
                /></div>
                <span v-for="adjust in service.Adjustments" :key="adjust.GroupCode">
                    <Input
                      type="text"
                      placeholder="Api Service Group Code"
                      v-model="adjust.GroupCode"
                      label="Api Service Group Code"
                      :disabled="isDisabled"
                    />
                    <Input
                      type="text"
                      placeholder="Api Service Adj Amount"
                      v-model="adjust.AdjAmount"
                      label="Api Service Adj Amount"
                      :disabled="isDisabled"
                    />
                    <Input
                      type="text"
                      placeholder="Api Service Reason Code"
                      v-model="adjust.ReasonCode"
                      label="Api Service Reason Code"
                      :disabled="isDisabled"
                    />
                    <Input
                      type="text"
                      placeholder="Api Service Adj Quantity"
                      v-model="adjust.AdjQuantity"
                      label="Api Service Adj Quantity"
                      :disabled="isDisabled"
                    />
                </span>
                <span v-for="remark in service.RemarkCodes" :key="remark.RemarkCode">
                    <Input
                      type="text"
                      placeholder="Api Service Group Code"
                      v-model="remark.RemarkCode"
                      label="Api Service Group Code"
                      :disabled="isDisabled"
                    />
                    <Input
                      type="text"
                      placeholder="Api Service Group Code"
                      v-model="remark.RemarkType"
                      label="Api Service Group Code"
                      :disabled="isDisabled"
                    />
                </span>
            </div>
            <!-- new entries end-->

        </form>
    </div>
</template>
<script>
    export default {
        name: 'InsuranceERADetail',
        props: {
            record_id: [String, Number],
        },
        data() {
            return {
                record_data : {
                    active : 0,
                    insurance_payer_id : 1,
                    payer_name : '',
                    payer_zip : '',
                    payer_acct : '',
                    check_num : '',
                    era_id : '',
                    paid_date : 'dayt',
                    paid_amount : 1,
                    provider_acct : '',
                    provider_name : '',
                    provirder_taxid : '',
                    provider_zip : '',
                    insurance_claim_ids : '',
                    api_response_json : '',
                    api_response_object: {
                        PatID: '',
                        GroupNo: '',
                        DRGCode: '',
                        ParentID: '',
                        EditDate: '',
                        EditByID: '',
                        Balanced: '',
                        TransID: '',
                        Services: [],
                        DRGWeight: '',
                        MediaCode: '',
                        PatientID: {
                            MemberID: ''
                        },
                        TransType: '',
                        CreateDate: '',
                        CreateMode: '',
                        PayerCtlNo: '',
                        StatusCode: '',
                        ClaimCharge: '',
                        CoverageAmt: '',
                        PatientLast: '',
                        PatientResp: '',
                        ProvMatchID: '',
                        TransStatus: '',
                        AssignedToID: '',
                        ClaimPayment: '',
                        CrcPatientID: {
                            ChangedID: ''
                        },
                        FacilityType: '',
                        PatientCtlNo: '',
                        PatientFirst: '',
                        PayerMatchID: '',
                        ReceivedDate: '',
                        RemitClaimID: '',
                        RemitGroupID: '',
                        SourceFileID: '',
                        StatementEnd: '',
                        TransmitDate: '',
                        ClaimFreqCode: '',
                        ImportBatchID: '',
                        PatientMiddle: '',
                        ProvPartnerID: '',
                        CrcPatientLast: '',
                        PayContactName: '',
                        PayerPartnerID: '',
                        RemitPaymentID: '',
                        StatementStart: '',
                        ClaimFilingCode: '',
                        ClassContractNo: '',
                        CrcPatientFirst: '',
                        PayContactPhone: '',
                        RenderingProvID: {
                            NPI: ''
                        },
                        DischargeFraction: '',
                        RenderingProvLast: '',
                        RenderingProvFirst: '',
                        UnderpaymentAmount: 0,
                    },
                    client_id : '',
                },
                isDisabled : true,
                record_actions : {
                    getRecordDetails: (id) => `/insurance/era/${id}`,
                }
            }
        },
        methods: {
            async init() {
                if (!(this.record_id === 'new')) {
                    try {
                        const res = await this.$api.get(this.record_actions.getRecordDetails(this.record_id))

                        if (res.status === 404 || res.status === 500) {
                            this.$toasted.error('Could not find record')
                            return this.$router.replace({ name: 'NotFound' })
                        } else {
                            const apiResponseJson = JSON.parse(res.data.info[0].api_response_json);
                            this.record_data = res.data.info[0]
                            this.record_data.api_response_object = apiResponseJson;
                        }
                    } catch (e) {
                        console.log('error: ', e);
                    }
                }
            },
            async validateForm() {
                const isValid = 1 // await this.$refs.form.validate()

                if (!isValid) {
                    this.$toasted.error('Please fill out all required fields.')
                }

                return isValid
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            async $route() {
                await this.init()
            },
        },
    }
</script>
